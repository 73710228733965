
import { Options, mixins } from "vue-class-component"
import Join from "@/components/Join/index.vue"
import Panelist from "@/mixins/Panelist"
import { preventAnyNavigation } from "@/utils/routerHelpers"

@Options({
  components: {
    Join
  },
  beforeRouteLeave(...arg) {
    preventAnyNavigation(...arg)
  }
})
export default class allFinished extends mixins(Panelist) {}
